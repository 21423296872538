@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body{
	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
    
}
*{
	margin:0;
	padding:0;
	box-sizing: border-box;
    
}
.carousel-jpg{
    width: 100%;
    min-height: 490px;
    margin-top: 1em
    
}


.img-tres{
    border-radius: 20px;
    transition: width 2s, height 2s, transform 2s;
}

.img-tres:hover {
    width: 200px;
    height: 200px;
    transform: rotate(360deg);
  }

.new-title{
    text-align: center;
   font-weight: bold;
    font-size: 30px;
    position: relative;
    animation-name: example;
    animation-duration: 5s;
    margin-bottom: 1px;
}

@keyframes example {
    from  { left:-200px; top:0px;}
    to  { left:0px; top:0px;}
   
  }

.new-banner1{
    position: relative;
    animation-name: banner1;
    animation-duration: 10s;
    margin-bottom: 1px;
}

@keyframes banner1 {
    from  { left:-200px; top:0px;}
    to  { left:0px; top:0px;}
   
  }

  .new-banner2{
    position: relative;
    animation-name: banner2;
    animation-duration: 10s;
    margin-bottom: 1px;
}

@keyframes banner2 {
    from  { right:-200px; top:0px;}
    to  { right:0px; top:0px;}
   
  }

  


@media(max-width: 500px){
    .carousel-jpg{
        width: 100%;
        min-height: 200px;
        margin-top: 1em;
  }
}

.container-video{
    display: flex;
    align-items: center;
    justify-content: center;
}
.video{
    margin-top: 5%;
    width: 60%;
}

@media(max-width: 500px){
    .video{
        margin-top: 2%;
        width: 100%;
  }
}


.slick-arrow{
    background-color: transparent !important;
    border: none !important;
    height: 120px !important;
    color: white !important;
    margin-top: -60px !important;
    position: absolute !important;
    width: 30px !important;
    z-index: 1 !important;
}
.slick-arrow:hover{
    background-color: rgba(78, 105, 23, .1) !important;
    color: white !important;
}
.ant-carousel .slick-next {
    right: 10px !important;
}
.ant-carousel .slick-prev {
    left: 10px !important;
}
span.anticon.anticon-right.slick-arrow.slick-next, span.anticon.anticon-left.slick-arrow.slick-prev{
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    font-size: 20px !important;
    color: #fff !important;
}

.auth-center{
    align-items: center;
    display: flex;
    flex-direction: column; 
    height: 100vh;
    justify-content: center;
}

.header-principal {
    /* background-color: #ECB368; */
    
    background-image: url('https://png.pngtree.com/thumb_back/fh260/back_pic/04/44/16/115853b2f40b5e6.jpg');
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    min-height: 200px;
    justify-content: center;
    padding: 5px 3px;
    border-radius: 0 0 30px 30px;
}

@media(max-width: 500px){
    .header-principal{
       padding: 8px 3px;
  }
}

.logo {
    height: 30%;
}

.btn-login {
    margin-top: 10px;
    background-color: #BE1A25 !important;
    border-color: #BE1A25 !important;
    float: left;
   
}

.header {
    position: relative;
    height: 90px;
    box-shadow: 0 0 10px #d1d1d1;
    color: red;
    width: 100%;
}

.div-img{
   
    display: flex;
    margin-top: 5%;
    width: 100%;
    height: 100vh;
}

.fondo-img{
    width: 90%;
    height: 100vh;
  
}

@media(max-width: 500px){
    .div-img{
        clear: both;
        display: block;
        margin-top: 5%;
        width: 100%;
        height: 100%;
    }
    .fondo-img{
        margin-bottom: 5%;
        width: 100%;
        height: 100vh;
    }
    
}

ul{
    list-style: none;
}


/*---------------FOOTER----------------------*/

.pie-pagina{
    width: 100%;
    background-color: #D02E39 ;
    /* background-image: url('https://img.freepik.com/fotos-premium/concepto-feliz-dia-padre-galletas-sobre-fondo-azul-madera-lugar-texto_178193-691.jpg'); */
}
.pie-pagina .grupo-1{
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 50px;
    padding: 45px 0px;
}

.pie-pagina .grupo-1 .box figure{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pie-pagina .grupo-1 .box figure img{
    width: 250px;
}

.pie-pagina .grupo-1 .box h2{
    color:white;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: bold;
}

.pie-pagina .grupo-1 .box p{
    color: white;
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: bold;
}

.pie-pagina .grupo-1 .red-social a{
    display: inline-block;
    text-decoration: none;
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: #E1C7AC;
    margin-right: 10px;
    background-color: white;
    text-align: center;
    transition: all 300ms ease;
}

.pie-pagina .grupo-1 .red-social a:hover{
    color: #E1C7AC;
}

.pie-pagina .grupo-2{
    text-align: center;
    /* background-color: #62CEAC ;
    padding:15px 10px;
    color: #DE0000; */
}

.pie-pagina .grupo-2 small{
    font-size: 15px;
}

@media screen and (max-width:800px){
    .pie-pagina .grupo-1{
        width: 90%;
        grid-template-columns: repeat(1,1fr);
        grid-gap: 30px;
        padding: 35px 0px;
        text-align: center;

    }
}

/*----------------------FOTOS DEL CATALOGO-------------------*/

.titulo-categorias{
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    margin-top: 0.5em;
}

@media(max-width: 500px){
    .titulo-categorias{
        margin-top: 1px;
    }
  
}

.fab-item.undefined{
    right: 1px;

}

.up-margin{
    margin-top: 1em;

}

.card-titulo{
    display: block;
    display: -webkit-box;
    height: 16px * 1.4 * 2;
    font-size: 16px;
    line-height: 1.4;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 25px;
}

.card-header{

    justify-content: space-between;
    position: relative;
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 20px solid #114B9C !important;
    border-radius: 10% 10% ;
    min-width: 100%;
    height: 100px;
    word-wrap: break-word;
}
.bold{
    font-weight: bold !important;
}
.image-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.image-container .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #fff;
    padding: 10px 10px 0 0;
    text-align: right;
}
.image-container:hover .after {
    display: block;
    background: rgba(187, 187, 187, 0.5);
}

.contentStyle{
    width: 100% !important;
    height: 600px !important;
    text-align: center !important;
    background: '#364d79';
    image-rendering: pixelated !important;
}

@media only screen and (max-width: 1111px) {
    .contentStyle{
        width: 100% !important;
        height: 400px !important;
        text-align: center !important;
        background: '#364d79';
        image-rendering: pixelated !important;
    }
}

/* vista de producto*/

.input-desc-usuario{
    border: none !important;
    height: 127px !important;
}
.input-titulo {
    overflow: hidden;
    border: none !important;
    font-size: 30px !important;
    cursor: default !important;
}
.foto-principal{
    width: 100%;
    height: 270px !important;
}

textarea.ant-input:hover{
    border: 1px solid #114B9C;
}
/*---------------------------------------------------------------------------*/

.image-upload{
    margin: 1p 1px 1px 1px;
    width: 206px;
    height: 30px;
    background-color: black;
    color: white;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}
#input[type="file"]{
    display: none;
}
.label{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.img-holder{
    margin: auto;
    width: 206px;
    height: 206px;
    border: 3px solid black;
    border-radius: 5px;
    margin-top: 1rem;
}
/*--------------------------------------------------------------*/
.sequency-images{
  
    display: inline-block;
    margin-left: 10px;
    position: relative;
    height: 285px;
}

.img_desc{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #114B9C;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    padding-left: 3px;
    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;

}

.sequency-images:hover .img_desc{
    visibility: visible;
    opacity: 1;
}

.reestablecer{
    margin-top: 1em;

}

.form-recu{
    display:'block';
    text-align:'center';
    width:50%;
}



@media(max-width: 500px){
    .reestablecer{
        margin-top: 1em;
       
    }
    .form-recu{
        width: 100%;
    }
    
}

.div-textarea{
    
    width: 50%;
    margin: auto;

}

.div-button{
    width: 50%;
    margin: auto;
    
}

@media(max-width: 500px){
    .div-textarea{
        width: 100%;
       
    }
    .div-button{
        width: 100%;
    }
   
    
}

.politicas-banner{
    height: 300px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    margin-bottom: 20px;
}
.politicas-title{
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}

h1.politicas{
    text-align: center !important;
    font-size: 40px !important;
    font-weight: 900 !important;
    color: #333333 !important;
    width: 100%;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
}
.text-style{
    font-size: 19px !important;
    text-align: justify !important;
    font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif !important;
    padding-bottom: 25px !important;
    color: #333333 !important;
    font-weight: 300 !important;
}
.no-padding{
    padding: 0x !important;
}





